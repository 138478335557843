.clickleable {
  cursor: pointer;
}

.clickleable:hover {
  color:blue;
  text-decoration: underline;
}

.messageTableEmpty{
  padding: 20px;
  font-size: 16px;
  color: #333;
}

.flex-gap-5{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.box-scroll {
  max-height: 450px;
  overflow-y: auto;
  padding: 10px 5px;
}

.form-separate{
  display: grid;
  gap: 15px
}

.card-border-line {
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 10px;
  /* box-shadow: 0 0 0px rgba(0, 0, 0, 0.5); */
  transition: box-shadow 0.3s;
}

.card-border-line:hover {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-fpalpha {
  display: flex;
}

.gap-5{
  gap: 5px
}

.justify-content-end {
  display: flex;
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.hidden-column{
  display: none;
}

.card-background-gray {
  margin-bottom: 10px;
  background: #eee;
  padding: 10px;
  border-radius: 10px
}

.grid_fp {
  display: grid;
  gap: 15px;
}

.text_undelined {
  text-decoration: underline;
  cursor: pointer
}

.helper_button {
  padding: 5px 10px !important;
  margin: 5px 0 !important;
}

.flex-wrap_fp_300 {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 300px;
}

.flex-wrap_fp {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.fp-alpha-highlight {
  color: #3155ff;
  font-size: 17px;
  /* text-shadow: 0px 0px 5px rgb(146, 146, 146); */
}
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before,
.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
  background-color: #5E76FF !important;
}

.grayaccent {
  color: #757575;
  padding-left: 5px;
  font-size: 11px;
}

.truncate-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;

}